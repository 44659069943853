<template>
    <div class="content-wrapper overflow-visible">
        <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />
        
        <div class="content">
            <validation-observer ref="VFormDokter">
                <b-form @submit.prevent="doSubmit()">
                <div class="card">
                    <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                        <h6 class="card-title font-weight-semibold">Kajian Awal Medis</h6>
                    </div>
                    <div class="card-body">
                        <div class="row g-2">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div>
                                    <label for="perKeluhan">Keluhan Utama<strong class="text-danger">*</strong></label>
                                    <b-form-input @input="onInput($event, 'keluhan-utama')" @blur="onBlur($event, 'keluhan-utama')" @focus="onFocus($event, 'keluhan-utama')" :disabled="disabled['keluhan-utama']" v-model="row.arankm_keluhan_utama" id="keluhan_utama" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Keluhan dari pasien" />
                                    </div>
                                    <p class='m-0 text-muted' v-if="disabled['keluhan-utama']">User lain sedang mengisi...</p>
                                    <VValidate 
                                        name="Keluhan Utama" 
                                        v-model="row.arankm_keluhan_utama" 
                                        :rules="toValidate(mrValidation.arankm_keluhan_utama)"
                                    />
                                </div>

                                <div class="form-group">
                                    <div>
                                    <label for="perRiwayat">Catatan Dokter</label>
                                    <b-form-textarea @input="onInput($event, 'catatan-dokter')" @blur="onBlur($event, 'catatan-dokter')" @focus="onFocus($event, 'catatan-dokter')" :disabled="disabled['catatan-dokter']" id="riwayat_penyakit_sekarang" v-model="row.arankm_riwayat_penyakit_sekarang" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Catatan Dokter"></b-form-textarea>
                                    </div>
                                    <p class='m-0 text-muted' v-if="disabled['catatan-dokter']">User lain sedang mengisi...</p>
                                    <VValidate 
                                        name="Riwayat Penyakit Sekarang" 
                                        v-model="row.arankm_riwayat_penyakit_sekarang" 
                                        :rules="toValidate(mrValidation.arankm_riwayat_penyakit_sekarang)"
                                    />

                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <div>
                                    <label for="perRiwayat">Riwayat Penyakit Dahulu</label>
                                    <b-form-textarea @input="onInput($event, 'riwayat-penyakit-dahulu')" @blur="onBlur($event, 'riwayat-penyakit-dahulu')" @focus="onFocus($event, 'riwayat-penyakit-dahulu')" :disabled="disabled['riwayat-penyakit-dahulu']"  id="riwayat" v-model="row.arankm_riwayat_penyakit" :formatter="normalText"  name="perRiwayat" rows="4" class="form-control" placeholder="Riwayat penyakit dari pasien"></b-form-textarea>
                                    </div>
                                    <p class='m-0 text-muted' v-if="disabled['riwayat-penyakit-dahulu']">User lain sedang mengisi...</p>
                                    <VValidate 
                                        name="Riwayat Penyakit" 
                                        v-model="row.arankm_riwayat_penyakit" 
                                        :rules="toValidate(mrValidation.arankm_riwayat_penyakit)"
                                    />
                                </div>
                            </div>
                            
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="head_panel_red">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h3>ALERGI </h3>
                                            <div>
                                            <b-form-radio-group
                                                id="has_alergi"
                                                @change="onInput($event, 'has-alergi')"
                                                @input="changeAlergi($event)"
                                                :options="Config.mr.yesNoOpt"
                                                v-model="row.arankm_has_alergi"
                                            />

                                            <VValidate 
                                                name="Alergi" 
                                                v-model="row.arankm_has_alergi" 
                                                :rules="toValidate(mrValidation.arankm_has_alergi)"
                                            />
                                            </div>
                                        </div>
                                        </div>
                                        
                                        <template v-if="row.arankm_has_alergi == 'Y'">
                                            <table class="table table-sm table-bordered">
                                                <thead>
                                                <tr>
                                                    <th width="33%">Jenis</th>
                                                    <th>Informasi Alergi</th>
                                                    <th width="64"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(v,k) in (row.arankm_alergi||[])" :key="k+'aler'">
                                                    <td>
                                                        <v-select @open="onFocus($event, 'alergi-jenis', k)" @close="onBlur($event, 'alergi-jenis', k)" @input="onInput($event, 'alergi-jenis', k)" :disabled="disabled['alergi-jenis'][k]" placeholder="Pilih Jenis" v-model="row.arankm_alergi[k]['jenis']" :options="Config.mr.jenisAlergi"  label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                        <p class='m-0 text-muted' v-if="disabled['alergi-jenis'][k]">User lain sedang mengisi...</p>
                                                        <VValidate 
                                                            :name="'Obat '+(k+1)" 
                                                            v-model="row.arankm_alergi[k]['jenis']" 
                                                            :rules="{required:1}"
                                                        />
                                                    </td>
                                                    <td>
                                                        <vue-bootstrap-autocomplete
                                                        @focus="onFocus($event, 'alergi-name', k)"
                                                        @blur="onBlur($event, 'alergi-name', k)"
                                                        :disabled="disabled['alergi-name'][k]"
                                                        v-model="row.arankm_alergi[k]['name']"
                                                        :data="mAlergi" @input="searchAlergi(row.arankm_alergi[k]['name']);onInput($event, 'alergi-name', k)"
                                                        placeholder="Pilih Alergi"
                                                        />
                                                        <p class='m-0 text-muted' v-if="disabled['alergi-name'][k]">User lain sedang mengisi...</p>
                                                        <VValidate 
                                                            :name="'Obat '+(k+1)" 
                                                            v-model="row.arankm_alergi[k]['name']" 
                                                            :rules="{required:1}"
                                                        />
                                                    </td>
                                                    <td>
                                                    <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td colspan="3" class="text-center">
                                                    <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                                    </td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                            <span class="text-warning d-none" id="alergi-typing">User Lain Sedang Mengisi ...</span>            
                                        </template>
                                        <template v-else>
                                            <table class="table table-sm table-bordered">
                                                <thead>
                                                <tr>
                                                    <th width="33%">Jenis</th>
                                                    <th>Informasi Alergi</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </td>
                                                    <td>
                                                    <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="head_panel_blue">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h3>OBAT RUTIN </h3>
                                            <div>
                                            <b-form-radio-group
                                                id="has_obat_rutin"
                                                @change="inputData('has_obat_rutin')"
                                                :options="Config.mr.yesNoOpt"
                                                v-model="row.arankm_has_obat_rutin"
                                            />

                                            <span class="text-warning d-none" id="has_obat_rutin-typing">User Lain Sedang Mengisi ...</span>            
                                            <VValidate 
                                                name="Obat Rutin" 
                                                v-model="row.arankm_has_obat_rutin" 
                                                :rules="toValidate(mrValidation.arankm_has_obat_rutin)"
                                            />
                                            </div>
                                        </div>
                                        </div>

                                        <div class="row mt-2" v-if="row.arankm_has_obat_rutin == 'Y'">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                            <label>Keterangan </label>
                                            <b-form-textarea id="keterangan_obat_rutin"  v-model="row.arankm_keterangan_obat_rutin" rows="5" cols="3" class="form-control" placeholder=""
                                                spellcheck="false"></b-form-textarea>
                                            </div>

                                            <span class="text-warning d-none" id="keterangan_obat_rutin-typing">User Lain Sedang Mengisi ...</span>          
                                            
                                            <VValidate 
                                                name="Keterangan" 
                                                v-model="row.arankm_keterangan_obat_rutin" 
                                                :rules="toValidate(mrValidation.arankm_keterangan_obat_rutin)"
                                            />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="card mb-2">
                                    <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                            <label>Tekanan Darah<span class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <b-form-input id="tekanan_darah_min" @blur="onBlur($event, 'tekanan-darah-min')" @focus="onFocus($event, 'tekanan-darah-min')" @input="onInput($event, 'tekanan-darah-min')" :disabled="disabled['tekanan-darah-min']"  :formatter="number"
                                                v-model="row.arankm_ttv_tekanan_darah_min" type="text" name="name"
                                                class="form-control" placeholder="Systole" />

                                                <div class="input-group-append input-group-prepend">
                                                <span class="input-group-text">/</span>
                                                </div>

                                                <b-form-input @blur="onBlur($event, 'tekanan-darah-max')" @focus="onFocus($event, 'tekanan-darah-max')" @input="onInput($event, 'tekanan-darah-max')" :disabled="disabled['tekanan-darah-max']" id="tekanan_darah_max"  :formatter="number" placeholder="Diastole"
                                                v-model="row.arankm_ttv_tekanan_darah_max" type="text"
                                                class="form-control" />
                                                <div class="input-group-append"><span class="input-group-text">mmHG</span>
                                                </div>
                                            </div>
                                                                                        
                                            <p class='m-0 text-muted' v-if="disabled['tekanan-darah-min'] || disabled['tekanan-darah-max']">User lain sedang mengisi...</p>

                                            <VValidate name="Tekanan Darah Min" v-model="row.arankm_ttv_tekanan_darah_min"
                                                :rules="toValidate(mrValidation.arankm_ttv_tekanan_darah_min)" />
                                            <VValidate name="Tekanan Darah Max" v-model="row.arankm_ttv_tekanan_darah_max"
                                                :rules="toValidate(mrValidation.arankm_ttv_tekanan_darah_max)" />
                                            </div>
                                        </div>

                                        <div class="col-md-5">
                                            <div class="form-group">
                                            <label>Nadi<span class="text-danger">*</span></label>
                                            <div class="form-row">
                                                <div class="col-md-12">
                                                <div class="input-group">
                                                    <b-form-input @blur="onBlur($event, 'nadi')" @focus="onFocus($event, 'nadi')" @input="onInput($event, 'nadi')" :disabled="disabled['nadi']" style="max-width: 80px;" id="nadi"  :formatter="number" v-model="row.arankm_ttv_nadi"
                                                    type="text" class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">x/mnt</span>
                                                    </div>
                                                    <div class="input-group-append">
                                                        <div style="width: 150px;">
                                                            <v-select id="label" @close="onBlur($event, 'nadi-label')" @open="onFocus($event, 'nadi-label')" @input="onInput($event, 'nadi-label')" :disabled="disabled['nadi-label']"  placeholder="Pilih Label" v-model="row.arankm_ttv_label"
                                                            :options="Config.mr.StatusRegular" label="text"
                                                            :clearable="true" :reduce="v=>v.value"></v-select>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <p class='m-0 text-muted' v-if="disabled['nadi'] || disabled['nadi-label']">User lain sedang mengisi...</p>                                        

                                            <VValidate name="Nadi" v-model="row.arankm_ttv_nadi"
                                                :rules="toValidate(mrValidation.arankm_ttv_nadi)" />

                                            <VValidate name="Label" v-model="row.arankm_ttv_label"
                                                :rules="toValidate(mrValidation.arankm_ttv_label)" />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                            <label>Gula Darah</label>
                                            <div class="form-row">
                                                <div class="col-md-12">
                                                <div class="input-group">
                                                    <b-form-input @blur="onBlur($event, 'gula-darah')" @focus="onFocus($event, 'gula-darah')" @input="onInput($event, 'gula-darah')" :disabled="disabled['gula-darah']" id="gula_darah"  :formatter="alphanum"
                                                    v-model="row.arankm_ttv_gula_darah" type="text"
                                                    class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">mg/dL</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <p class='m-0 text-muted' v-if="disabled['gula-darah']">User lain sedang mengisi...</p>

                                            <VValidate name="Gula Darah" v-model="row.arankm_ttv_gula_darah"
                                                :rules="toValidate(mrValidation.arankm_ttv_gula_darah)" />
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group">
                                            <label>Pernafasan<span class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <b-form-input id="pernafasan" @blur="onBlur($event, 'pernafasan')" @focus="onFocus($event, 'pernafasan')" @input="onInput($event, 'pernafasan')" :disabled="disabled['pernafasan']"  :formatter="number"
                                                v-model="row.arankm_ttv_pernafasan" type="text" class="form-control" />
                                                <div class="input-group-append"><span
                                                    class="input-group-text">x/mnt</span>
                                                </div>
                                            </div>
                                            
                                            <p class='m-0 text-muted' v-if="disabled['pernafasan']">User lain sedang mengisi...</p>

                                            <VValidate name="Pernafasan" v-model="row.arankm_ttv_pernafasan"
                                            :rules="toValidate(mrValidation.arankm_ttv_pernafasan)" />
                                            
                                            </div>

                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group">
                                            <label>SPO2</label>
                                            <div class="form-row">
                                                <div class="col-md-12">
                                                <div class="input-group">
                                                    <b-form-input id="spo2" @blur="onBlur($event, 'spo2')" @focus="onFocus($event, 'spo2')" @input="onInput($event, 'spo2')" :disabled="disabled['spo2']"  :formatter="number" v-model="row.arankm_ttv_spo2"
                                                    type="text" class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <p class='m-0 text-muted' v-if="disabled['spo2']">User lain sedang mengisi...</p>
                                            <VValidate name="SPO2" v-model="row.arankm_ttv_spo2"
                                                :rules="toValidate(mrValidation.arankm_ttv_spo2)" />
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group">
                                            <label>Suhu<span class="text-danger">*</span></label>
                                            <div class="form-row">

                                                <div class="col-md-12">
                                                <div class="input-group">
                                                    <b-form-input id="suhu" @blur="onBlur($event, 'suhu')" @focus="onFocus($event, 'suhu')" @input="onInput($event, 'suhu')" :disabled="disabled['suhu']" :formatter="number" v-model="row.arankm_ttv_suhu"
                                                    type="text" class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">C</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <p class='m-0 text-muted' v-if="disabled['suhu']">User lain sedang mengisi...</p>

                                            <VValidate name="Suhu" v-model="row.arankm_ttv_suhu"
                                                :rules="toValidate(mrValidation.arankm_ttv_suhu)" />
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group">
                                            <label>Berat Badan</label>
                                            <div class="form-row">

                                                <div class="col-md-12">
                                                <div class="input-group">
                                                    <b-form-input id="weight" @blur="onBlur($event, 'berat-badan')" @focus="onFocus($event, 'berat-badan')" :disabled="disabled['berat-badan']" @input="hitungBMI();onInput($event, 'berat-badan')" :formatter="number"
                                                    v-model="row.arankm_ttv_weight" type="text" class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">kg</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <p class='m-0 text-muted' v-if="disabled['berat-badan']">User lain sedang mengisi...</p>

                                            <VValidate name="Berat Badan" v-model="row.arankm_ttv_weight"
                                                :rules="toValidate(mrValidation.arankm_ttv_weight)" />
                                            </div>
                                        </div>


                                        <div class="col-md-2">
                                            <div class="form-group">
                                            <label>Tinggi</label>
                                            <div class="form-row">

                                                <div class="col-md-12">
                                                <div class="input-group">
                                                    <b-form-input @blur="onBlur($event, 'tinggi')" @focus="onFocus($event, 'tinggi')" :disabled="disabled['tinggi']" @input="hitungBMI();onInput($event, 'tinggi')"  :formatter="number"
                                                    v-model="row.arankm_ttv_height" type="text" class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">cm</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <p class='m-0 text-muted' v-if="disabled['tinggi']">User lain sedang mengisi...</p>

                                            <VValidate name="Tinggi Badan" v-model="row.arankm_ttv_height"
                                                :rules="toValidate(mrValidation.arankm_ttv_height)" />
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group">
                                            <label>Lingkar Kepala</label>
                                            <div class="form-row">

                                                <div class="col-md-12">
                                                <div class="input-group">
                                                    <b-form-input id="lingkar_kepala" @blur="onBlur($event, 'lingkar-kepala')" @focus="onFocus($event, 'lingkar-kepala')" :disabled="disabled['lingkar-kepala']" @input="hitungBMI();onInput($event, 'lingkar-kepala')" :formatter="number"
                                                    v-model="row.arankm_ttv_lingkar_kepala" type="text"
                                                    class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">cm</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <p class='m-0 text-muted' v-if="disabled['lingkar-kepala']">User lain sedang mengisi...</p>

                                            <VValidate name="Lingkar Kepala" v-model="row.arankm_ttv_lingkar_kepala"
                                                :rules="toValidate(mrValidation.arankm_ttv_lingkar_kepala)" />
                                            </div>
                                        </div>

                                        <div class="col-md-3" v-if="row.ap_usia <= 15">
                                            <div class="form-group">
                                            <label>Luas Permukaan Tubuh Anak</label>
                                            <div class="input-group">
                                                <b-form-input id="permukaan_anak" @blur="onBlur($event, 'permukaan-anak')" @focus="onFocus($event, 'permukaan-anak')" @input="onInput($event, 'permukaan-anak')" :disabled="disabled['permukaan-anak']" :formatter="number"
                                                v-model="row.arankm_ttv_luas_permukaan_anak" type="text"
                                                class="form-control" />
                                                <div class="input-group-append"><span
                                                    class="input-group-text">m<sup>2</sup></span></div>
                                            </div>
                                            </div>

                                            <p class='m-0 text-muted' v-if="disabled['permukaan-anak']">User lain sedang mengisi...</p>
                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group">
                                            <label>BMI</label>
                                            <div class="form-row">
                                                <div class="col-md-12">
                                                <div class="input-group">
                                                    <b-form-input disabled v-model="row.arankm_ttv_bmi" type="text"
                                                    class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">m2</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <VValidate name="BMI" v-model="row.arankm_ttv_bmi"
                                                :rules="toValidate(mrValidation.arankm_ttv_bmi)" />
                                            </div>
                                        </div>


                                        <div class="col-md-3">
                                            <div class="form-group">
                                            <label>Kesadaran</label>
                                            <button type="button" @click="isOpenTS=true" style="display: block;" class="btn btn-light mr-3">Isi Tingkat Kesadaran</button>
                                            <template v-if="row.arankm_response_gcs">
                                                <div class="">
                                                <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                                <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                                <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                                <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                                <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                                <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                                <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                                <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                                </div>
                                            </template>
                                            
                                            <VValidate name="Kesadaran" v-model="row.arankm_ttv_kesadaran"
                                                :rules="toValidate(mrValidation.arankm_ttv_kesadaran)" />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Pupil</label>
                                                <v-select @close="onBlur($event, 'pupil')" @open="onFocus($event, 'pupil')" @input="onInput($event, 'pupil')" :disabled="disabled['pupil']" placeholder="Pilih Pupil" v-model="row.arankm_ttv_pupil"
                                                    :options="Config.mr.ranapDataPupil" label="text" :clearable="true"
                                                    :reduce="v=>v.value">
                                                    <template slot="selected-option" slot-scope="option">
                                                    <span v-b-tooltip.hover
                                                        :title="option.text">{{ option.text }}</span>
                                                    </template>
                                                    <template slot="option" slot-scope="option">
                                                    <span v-b-tooltip.hover
                                                        :title="option.text">{{ option.text }}</span>
                                                    </template>
                                                </v-select>
                                                <p class='m-0 text-muted' v-if="disabled['pupil']">User lain sedang mengisi...</p>
                                            </div>
                                        </div>

                                        </div>
                                    </div>
                                </div>
                            
                                <div class="card mb-2">
                                    <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
                                    </div>
                                    <div class="card-body p-3">
                                    <div class="alert alert-info">Silakan pilih/ceklis bagian yang memiliki kelainan</div>
                                        <div class="card border shadow-0">
                                            <div class="bg-light card-header c-pointer"
                                            @click="pemeriksaanFisik = !pemeriksaanFisik"
                                            :class="pemeriksaanFisik ? null : 'collapsed'"
                                            :aria-expanded="pemeriksaanFisik ? 'true' : 'false'"
                                            aria-controls="GenPartRow">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <span class="card-title font-weight-semibold">Klik untuk
                                                {{ pemeriksaanFisik ? "Menyembunyikan" : "Menampilkan" }} Bagian Keadaan
                                                Umum</span>
                                                <i class="icon-arrow-down22" style="font-size: 20px;"></i>
                                            </div>
                                            </div>
                                        <div>
                                        
                                        <b-collapse id="GenPartRow" v-model="pemeriksaanFisik">
                                            <div class="row g-0">
                                                <div class="col-md-6" v-for="(genCol, genColKey) in oddEvenGenCondition"
                                                :key="genColKey+'genCol'">
                                                <div class="gen-part" v-for="(vs,ks) in genCol" :key="ks+'sign'">
                                                    <div
                                                    class="d-flex align-items-center justify-content-between gen-part-head c-pointer"
                                                    @click="row[vs.value+'isOpen'] = !row[vs.value+'isOpen']"
                                                    :class="row[vs.value+'isOpen'] ? null : 'collapsed'">
                                                    <div class="font-weight-semibold">{{vs.label}}
                                                        <span class="text-danger" v-if="row[vs.value] == 'TN'">*</span>
                                                    </div>
                                                    <i class="icon-arrow-down22"></i>
                                                    </div>

                                                    <b-collapse :id="vs.value" v-model="row[vs.value+'isOpen']"
                                                    accordion="accKeadaanUmum">
                                                    <div class="gen-part-body mt-1">
                                                        <div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" v-model="row[vs.value]"
                                                            @input="changeVital(vs.value,'N')"
                                                            class="custom-control-input" :name="`formType${vs.label}`"
                                                            :id="`normalCheck${vs.label}`" value="N">
                                                            <label class="custom-control-label"
                                                            :for="`normalCheck${vs.label}`">
                                                            Normal
                                                            </label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" v-model="row[vs.value]"
                                                            @input="changeVital(vs.value,'TN')"
                                                            class="custom-control-input" :name="`formType${vs.label}`"
                                                            :id="`notNormalCheck${vs.label}`" value="TN">
                                                            <label class="custom-control-label"
                                                            :for="`notNormalCheck${vs.label}`">
                                                            Tidak Normal
                                                            </label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" v-model="row[vs.value]"
                                                            class="custom-control-input" :name="`formType${vs.label}`"
                                                            :id="`notCheck${vs.label}`" value="TP">
                                                            <label class="custom-control-label"
                                                            :for="`notCheck${vs.label}`">
                                                            Tidak Diperiksa
                                                            </label>
                                                        </div>
                                                        </div>
                                                        <div class="gen-input mt-2" v-if="row[vs.value] !== 'TP'">
                                                                <template v-if="vs.value == 'arankm_vs_saraf' && row[vs.value] == 'TN'">
                                                                    <div class="gen-input-saraf" data-select2-id="162">
                                                                    <div class="mb-2">
                                                                        <label for="refleksFisiologis">Refleks Fisiologis</label>
                                                                        <b-textarea
                                                                        v-model="row.arankm_vs_saraf_r_fisiologis" class="form-control"
                                                                        rows="2"></b-textarea>
                                                                    </div>
                                                                    <div class="mb-2">
                                                                        <label for="refleksPatologis">Refleks Patologis</label>
                                                                        <b-textarea
                                                                        v-model="row.arankm_vs_saraf_r_patologis" class="form-control"
                                                                        rows="2"></b-textarea>
                                                                    </div>

                                                                    <div class="row g-1">
                                                                        
                                                                        <div class="col-md-6">
                                                                            <label>Babinski</label>
                                                                            <v-select placeholder="Status" 
                                                                            v-model="row.arankm_vs_saraf_babinski" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                                        </div>

                                                                        <div class="col-md-6">
                                                                            <label>Lasegue</label>
                                                                            <v-select placeholder="Status" 
                                                                            v-model="row.arankm_vs_saraf_lasegue" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                                        </div>
                                                                        
                                                                        <div class="col-md-6">
                                                                            <label>Bregard</label>
                                                                            <v-select placeholder="Status" 
                                                                            v-model="row.arankm_vs_saraf_bregard" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                                        </div>
                                                                        
                                                                        <div class="col-md-6">
                                                                            <label>Sicard</label>
                                                                            <v-select placeholder="Status" 
                                                                            v-model="row.arankm_vs_saraf_sicard" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                                        </div>
                                                                        
                                                                        <div class="col-md-6">
                                                                            <label>Kaku Duduk</label>
                                                                            <v-select placeholder="Status" 
                                                                            v-model="row.arankm_vs_saraf_kaku_duduk" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                                        </div>

                                                                        <div class="col-md-6">
                                                                            <label>Kernig Sign</label>
                                                                            <v-select placeholder="Status" 
                                                                            v-model="row.arankm_vs_saraf_kernig_sign" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                                        </div>

                                                                        <div class="col-md-6">
                                                                            <label>Brudzinski 1</label>
                                                                            <v-select placeholder="Status" 
                                                                            v-model="row.arankm_vs_saraf_brudzinski" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                                        </div>
                                                                        
                                                                        <div class="col-md-6">
                                                                            <label>Brudzinski 2</label>
                                                                            <v-select placeholder="Status" 
                                                                            v-model="row.arankm_vs_saraf_brudzinski2" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                                        </div>

                                                                    </div>
                                                                    
                                                                    </div>
                                                                </template>
                                                                <template v-else>
                                                                    <b-textarea
                                                                        v-model="row[vs.value+'_text']" class="form-control"
                                                                        :placeholder="`cth. pembengkakan pada bagian ${vs.label} sebelah kiri`"
                                                                        rows="2"></b-textarea>

                                                                    <VValidate :name="`${vs.label}`" v-model="row[vs.value+'_text']"
                                                                        :rules="{required:1, min:2, max:512}" />
                                                                </template>
                                                        </div>
                                                    </div>
                                                    </b-collapse>
                                                </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-8">
                            <div class="card border shadow-0">
                                <div class="bg-light card-header py-2">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                                    <!--
                                    <a href="javascript:;" v-if="riwayatICD10.length" @click="openriwayatICD10 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                                    <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                                    </a>
                                    -->
                                </div>
                                </div>
                                <div class="card-body">
                                <div class="form-group mb-0">
                                    <div class="input-group">
                                    <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                        <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                            v-model="inputICD10"
                                            @search="filterICD10($event,0)"
                                            @input="selectICD10(inputICD10)"
                                            :options="mICD10" label="text"
                                            :clearable="true" :reduce="v=>v.value">
                                            
                                            <template slot="selected-option">
                                            <span v-b-tooltip.hover.right :title="inputICD10Name">
                                                {{inputICD10Name||"-"}}
                                            </span>
                                            </template>
                                            
                                            <template slot="option" slot-scope="option">
                                            <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                            </template>
                                            <template #list-footer v-if="(mICD10||[]).length >= 1">
                                            <li class="list-paging">
                                                <div class="d-flex align-items-center justify-content-between">
                                                <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                                    size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                    class="icon-arrow-left22 text-white"></i></b-button>
                                                <small>Halaman {{mICD10[0].currentPage}} dari
                                                    {{ceilData(mICD10[0].totalRows/5)}}</small>
                                                <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                                    size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                    class="icon-arrow-right22 text-white"></i></b-button>
                                                </div>
                                            </li>
                                            </template>
                                        </v-select>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                                <thead>
                                    <tr>
                                    <th>Item ICD-10 dipilih</th>
                                    <th width="64">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                                    <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                                    <td>
                                        <a href="javascript:;" @click="removedataICD10(v.arankai10d_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                        <i class="icon-trash-alt"></i>
                                        </a>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <div class="card-body py-2" v-else>
                                <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                                </div>
                                <div class="card-body pt-2">
                                <div class="form-group mb-0">
                                    <label for="inputManualDiagnose">
                                    Diagnosa Lainnya <strong class="txt_mandatory" v-if="!(dataICD10||[]).length">*</strong>
                                    <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi diagnosa secara manual"><i class="icon-info22 text-indigo"></i></span>
                                    </label>
                                    <b-textarea v-model="row.arankm_diagnosa_text" name=""
                                    id="" rows="3" class="form-control"></b-textarea>
                                    
                                <VValidate v-if="!(dataICD10||[]).length" :name="`Diagnosa`" v-model="row.arankm_diagnosa_text"
                                    :rules="{required:1, min:2, max:512}" />
                                </div>
                                </div>
                            </div>
                            </div>
                            
                            <!--
                            <div class="col-md-6">
                            <div class="card border shadow-0">
                                <div class="bg-light card-header py-2">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
                                </div>
                                </div>
                                <div class="card-body">
                                <div class="form-group mb-0">
                                    <div class="input-group">
                                    <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                        <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                            v-model="inputICD9"
                                            @search="filterICD9($event,0)"
                                            @input="selectICD9(inputICD9)"
                                            :options="mICD9" label="text"
                                            :clearable="true" :reduce="v=>v.value">
                                            
                                            <template slot="selected-option">
                                            <span v-b-tooltip.hover.right :title="inputICD9Name">
                                                {{inputICD9Name||"-"}}
                                            </span>
                                            </template>
                                            
                                            <template slot="option" slot-scope="option">
                                            <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                            </template>
                                            <template #list-footer v-if="(mICD9||[]).length >= 1">
                                            <li class="list-paging">
                                                <div class="d-flex align-items-center justify-content-between">
                                                <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')"
                                                    size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                    class="icon-arrow-left22 text-white"></i></b-button>
                                                <small>Halaman {{mICD9[0].currentPage}} dari
                                                    {{ceilData(mICD9[0].totalRows/5)}}</small>
                                                <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')"
                                                    size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                    class="icon-arrow-right22 text-white"></i></b-button>
                                                </div>
                                            </li>
                                            </template>
                                        </v-select>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                                <thead>
                                    <tr>
                                    <th>Item ICD-9 dipilih</th>
                                    <th width="64">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                                    <td>
                                    <span>{{v.mi9_name||"-"}}<span class="font-weight-semibold" v-if="v.mi9_code">[{{v.mi9_code||"-"}}]</span></span>
                                    <div v-if="v.mi9_informed_consent"><small class="font-weight-medium text-info-800">Tindakan memerlukan informed consent</small></div>
                                    </td>
                                    <td>
                                        <a href="javascript:;" @click="removedataICD9(v.arankai9d_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger" v-b-tooltip.hover title="Hapus Data ICD 9">
                                        <i class="icon-trash-alt"></i>
                                        </a>
                                        
                                        <a href="javascript:;" @click="openInformConsent(v)" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo"  v-b-tooltip.hover :title="v.arankai9d_informed_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                                        <i class="icon-file-check2"></i>
                                        </a>

                                        <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.arankai9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                        <i class="icon-file-eye2"></i>
                                        </a>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <div class="card-body py-2" v-else>
                                <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                                </div>
                                <div class="card-body pt-2">
                                <div class="form-group mb-0">
                                    <label for="inputManualDiagnose">
                                    Tindakan Lainnya
                                    <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                                    </label>
                                    <b-textarea v-model="row.arankm_tindakan_text" name=""
                                    id="" rows="3" class="form-control"></b-textarea>

                                    <a href="javascript:;" v-if="row.arankm_tindakan_text" @click="openInformConsentForm"  class="btn btn-sm mr-2 mt-2 btn-icon alpha-indigo text-indigo-800 border-indigo"><i class="icon-file-check2"></i> 
                                    {{row.arankm_inform_consent_id ? 'Edit Informed Consent' : 'Tambah Informed Consent'}}
                                    </a>
                                    
                                    <a href="javascript:;" @click="openInformConsentDetailForm" v-if="row.arankm_inform_consent_id" class="btn btn-sm mr-2 mt-2 btn-icon alpha-indigo text-indigo-800 border-indigo"><i class="icon-file-check2"></i> Lihat Inform Consent</a>

                                    <a href="javascript:;" v-if="row.arankm_inform_consent_id" @click="removeICDForm()" class="btn btn-sm mr-2 mt-2 btn-icon alpha-danger text-danger-800 border-danger"><i class="icon-trash-alt"></i> Hapus Inform Consent</a>
                                </div>
                                </div>
                            </div>
                            </div>
                            -->
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-8">
                                <div class="card border shadow-0">
                                    <div class="bg-light card-header py-2">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h6 class="card-title mb-0 font-weight-semibold">RENCANA PENGOBATAN DAN TINDAKAN</h6>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0 p-3">
                                        <b-textarea v-model="row.arankm_rencana_tindakan" name=""
                                        id="" rows="3" class="form-control"></b-textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card border shadow-0" v-if="row.isEdit">
                            <div class="card-header bg-info">
                                <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="addInfoTindakan">Keterangan Perubahan</label>
                                    <b-textarea v-model="row.aranrm_notes" :formatter="normalText" rows="6" class="form-control"
                                        placeholder="Keterangan Perubahan">
                                    </b-textarea>

                                    <!--
                                    <VValidate :name="`Keterangan Perubahan`" v-model="row.aranrm_notes"
                                        :rules="{required: 1, min: 2, max:512}" />
                                    -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="text-right">
                            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                </div>
                </b-form>
            </validation-observer>
        </div>

        
        <b-modal v-model="isOpenTS" :title="'Isi Tingkat Kesadaran'" size="xl" ok-only ok-title="Simpan">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                <th>Pengkajian</th>
                                <th>Nilai</th>
                                </tr>
                            </thead>
                            <template v-if="rowReg.aranr_kajian_awal_type == 'NICU'">   
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                                    
                                    <!-- ele pertama hardcode -->
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-mata')"  v-model="row.arankm_response_mata" name="arankm_response_mata" :value="4">Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                                    </td>

                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseMataNICU||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-mata')" v-model="row.arankm_response_mata" name="arankm_response_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                                    
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-verbal')"  v-model="row.arankm_response_verbal" name="arankm_response_verbal" :value="5">Berbicara mengoceh seperti biasa<span class="text-blue-800 font-weight-semibold">(5)</span>
                                    </b-form-radio>
                                    </td>
                                    
                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseVerbalNICU||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-verbal')"  v-model="row.arankm_response_verbal" name="arankm_response_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                                    
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-motorik')"  v-model="row.arankm_response_motorik" name="arankm_response_motorik" :value="6">Bergerak spontan<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                                    </td>
                                    
                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseMotorikNICU||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-motorik')" v-model="row.arankm_response_motorik" name="arankm_response_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                            <template v-else>
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                                    
                                    <!-- ele pertama hardcode -->
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-mata')" v-model="row.arankm_response_mata" name="arankm_response_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                                    </td>

                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-mata')" v-model="row.arankm_response_mata" name="arankm_response_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                                    
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-verbal')"  v-model="row.arankm_response_verbal" name="arankm_response_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
                                    </b-form-radio>
                                    </td>
                                    
                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-verbal')"  v-model="row.arankm_response_verbal" name="arankm_response_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                                    
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-motorik')" v-model="row.arankm_response_motorik" name="arankm_response_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                                    </td>
                                    
                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-motorik')" v-model="row.arankm_response_motorik" name="arankm_response_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                            <tfoot>
                                <tr>
                                <td class="table-info" colspan="9">
                                    <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                    <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                    <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </td>
                                </tr>
                            </tfoot>
                            </table>

                            <!--
                            <VValidate 
                                name="Response Mata" 
                                v-model="row.arankm_response_mata" 
                                :rules="toValidate(mrValidation.arankm_response_mata)"
                                class="d-block"
                            />

                            
                            <VValidate 
                                name="Response Verbal" 
                                v-model="row.arankm_response_verbal" 
                                :rules="toValidate(mrValidation.arankm_response_verbal)"
                                class="d-block"
                            />
                            
                            <VValidate 
                                name="Response Motorik" 
                                v-model="row.arankm_response_motorik" 
                                :rules="toValidate(mrValidation.arankm_response_motorik)"
                                class="d-block"
                            />
                            -->


                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        
        <b-modal v-model="openriwayatICD10" :title="'Pilih ICD10 dari Riwayat Pasien'" size="xl" hide-footer>
        <div class="modal-body p-0">
            <div class="mb-3">
            <div class="row">
                <div class="col-md-5 col-lg-4">
                <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                    <input v-model="searchICD10" @input="searchICD10Data" class="form-control"
                        placeholder="Ketik Nama Dokter / No. Registrasi" />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                </div>
                </div>
            </div>
            </div>
            <div class="table-responsive">
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>ICD10</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in riwayatICD10">
                    <tr :class="selectedICD10 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.arm_id" v-model="selectedICD10" name="historySelectionDiagnose"
                            :id="`histdiag${k+1}`" class="custom-control-input">
                        <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                        </div>

                    </td>

                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                        <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.mi10_name||"-"}},{{v.api10_diagnosis||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(riwayatICD10||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <div class="text-right">
            <button data-dismiss="modal" @click="openriwayatICD10 = false" class="btn">Batal</button>
            <button data-dismiss="modal" @click="saveICD10" :disabled="!selectedICD10"
                class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
                Pilihan</button>
            </div>
        </div>
        </b-modal>

        <b-modal v-model="openriwayatICD9" :title="'Pilih ICD9 dari Riwayat Pasien'" size="xl" hide-footer>
        <div class="modal-body p-0">
            <div class="mb-3">
            <div class="row">
                <div class="col-md-5 col-lg-4">
                <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                    <input v-model="searchICD9" @input="searchICD9Data" class="form-control"
                        placeholder="Ketik Nama Dokter / No. Registrasi" />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                </div>
                </div>
            </div>
            </div>
            <div class="table-responsive">
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>ICD9</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in riwayatICD9">

                    <tr :class="selectedICD9 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.arm_id" v-model="selectedICD9" name="historySelectionDiagnose"
                            :id="`histdiag${k+1}`" class="custom-control-input">
                        <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                        </div>

                    </td>

                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                        <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.mi9_name||"-"}},{{v.api9_tindakan||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(riwayatICD9||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <div class="text-right">
            <button data-dismiss="modal" @click="openriwayatICD9 = false" class="btn">Batal</button>
            <button data-dismiss="modal" @click="saveICD9" :disabled="!selectedICD9"
                class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
                Pilihan</button>
            </div>
        </div>
        </b-modal>

        <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.pasienSetuju"
                            v-model="rowIC.aranic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.persetujuanTindakan"
                            v-model="rowIC.aranic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
        </validation-observer>

        <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
            <table class="table table-bordered table-sm table-input">
            <thead>
                <tr>
                <td colspan="3">
                    <div class="result_tab">
                        <h4>Nama Tindakan / ICD-9-CM</h4>
                        <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                    </div>
                </td>
                </tr>
                <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                <td>
                {{rowIC.aranic_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td>
                {{rowIC.aranic_dasar_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td>
                {{rowIC.aranic_tindakan_dokter||"-"}}
                </td>
                </tr>
                <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td>
                {{rowIC.aranic_indikasi_tindakan||"-"}}
                </td>
                </tr>
                <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td>
                {{rowIC.aranic_tata_cara||"-"}}
                </td>
                </tr>
                <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td>
                {{rowIC.aranic_tujuan||"-"}}
                </td>
                </tr>
                <tr>
                <td>7</td>
                <td>Risiko</td>
                <td>
                {{rowIC.aranic_risiko||"-"}}
                </td>
                </tr>
                <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td>
                {{rowIC.aranic_kompilasi||"-"}}
                </td>
                </tr>
                <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td>
                {{rowIC.aranic_prognosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td>
                {{rowIC.aranic_alternatif||"-"}}
                </td>
                </tr>
                <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td>
                {{rowIC.aranic_lainnya||"-"}}
                </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div class="result_tab">
                    <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                    <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                    <p v-else>Tindakan ditolak</p>
                    </div>
                </td>
                <td width="50%">
                    <div class="result_tab">
                    <h4>Persetujuan tindakan diberikan kepada</h4>
                    <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                    <p v-else>Penanggung Jawab</p>
                    </div>
                </td>
                </tr>
            </tfoot>
            </table>
        </b-modal>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'


export default {
    extends: GlobalVue,
    components: {PatientInfo},
    data(){
        return {
            rowReg: {},
            mAlergi: [],
            mKesadaran: [],
            last_page: 1,
            pemeriksaanFisik : false,
            
            dataICD9: [],
            dataICD10: [],
            riwayatICD9: [],
            riwayatICD10: [],
            
            mICD9:[],
            mICD10:[], 

            openriwayatICD10: false,
            openriwayatICD9: false,
            
            selectedICD9: '',
            selectedICD10: '',
            
            searchICD10: '',
            searchICD9: '',
            
            inputICD9: null,
            inputICD10: null,
            
            inputICD9Name: null,
            inputICD10Name: null,
            searchICD10Name: null,
            searchICD9Name: null,

            rowIC : {
                aranic_is_pasien_setuju: null,
                aranic_persetujuan_tindakan: null
            },

            openModalIC : false,
            openModalICDetail : false,
            isOpenTS: false,
            
            patientData: {},
            loading: {
                patientInfo: false,
            },
            disabled: {
                'keluhan-utama': false,
                'riwayat-penyakit-dahulu': false,
                'catatan-dokter': false,
                'alergi-jenis': [],
                'alergi-name': [],
                'tekanan-darah-min': false,
                'tekanan-darah-max': false,
                'nadi': false,
                'nadi-label': false,
                'gula-darah': false,
                'pernafasa': false,
                'spo2': false,
                'suhu': false,
                'berat-badan': false,
                'tinggi': false,
                'lingkar-kepala': false,
                'permukaan-anak': false,
                'pupil': false,
            },
        }
    },

    computed: {            
        tingkatKesadaranTotal(){
            return (this.row.arankm_response_mata||0) + 
            (this.row.arankm_response_verbal||0) + 
            (this.row.arankm_response_motorik||0)
        },
        
        oddEvenGenCondition() {
            let odd = this.Config.mr.configVitalSignRanapDokter.filter((el, index) => index % 2)
            let even = this.Config.mr.configVitalSignRanapDokter.filter((el, index) => !(index % 2))
            let res = []
            res.push(even)
            res.push(odd)
            return res
        }
    },

    methods: {
        onFocus(v, field, index = false){
            const regId = this.$route.query.regId
            console.log(v)
            const payload = {
                to: 'kajian-awal',
                type: 'set-disable',
                field: field,
                regId: regId,
                index: index
            }
            this.$socket.emit('collaborate', payload)
        },
        onBlur(v, field, index = false){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal',
                type: 'set-enable',
                field: field,
                regId: regId,
                index: index
            }
            this.$socket.emit('collaborate', payload)
        },
        onInput: _.debounce(function(v, field, index = false){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal',
                type: 'set-value',
                field: field,
                regId: regId,
                value: v,
                index: index
            }

            this.$socket.emit('collaborate', payload)
        }, 500),
        
        
        back() {
            this.$router.back()
        },

        changeVital(field, n) {
            if (n == 'N') {
                this.row[field + '_text'] = 'TAK'
            } else {
                this.row[field + '_text'] = ''
            }
        },
      
        hitungBMI(){
            if(this.row.arankm_ttv_weight && this.row.arankm_ttv_height){
                this.row.arankm_ttv_bmi = this.row.arankm_ttv_weight / ((this.row.arankm_ttv_height/100)*(this.row.arankm_ttv_height/100))
                this.row.arankm_ttv_bmi = this.row.arankm_ttv_bmi.toFixed(2) 
            }else{
                this.row.arankm_ttv_bmi = null
            }
        },

        searchAlergi: _.debounce(function (e) {
          let data = {
              name: e,
              type: 'get-alergi'
          }
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
              this.mAlergi = res.data.data            
          })
        }, 100),
        
        changeAlergi(e){
            if(!(this.row.arankm_alergi||[]).length){
                this.addAlergi()
            }
        },
        
        addAlergi(){
            const data = {
                name : '',
                jenis : null,
            }
            if(!Array.isArray(this.row.arankm_alergi)){
                this.$set(this.row, 'arankm_alergi', [])
            }
            this.row.arankm_alergi.push(data)
            this.addMasterAlergi()

            const payload = {
                to: 'kajian-awal',
                type: 'add-alergi',
                regId: this.$route.query.regId,
                value: data
            }

            this.$socket.emit('collaborate', payload)
        },
        
        addMasterAlergi(){
            for(let i = 0; i < (this.row.arankm_alergi||[]).length; i++){
                let newData = this.row.arankm_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        
        removeAlergi(k){
            if(!Array.isArray(this.row.arankm_alergi)){
                this.$set(this.row, 'arankm_alergi', [])
            }
            this.row.arankm_alergi.splice(k,1)
            const payload = {
                to: 'kajian-awal',
                type: 'delete-alergi',
                regId: this.$route.query.regId,
                index: k
            }

            this.$socket.emit('collaborate', payload)
        },

        toValidate(val){
            return {...val}
        },
        inputData(field){
            console.log(field)
            console.log("for realtime")
        }, 
        apiGet(params = {}, page = 1){
            if(!this.pageSlug || !this.$route.query.regId){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.pageNow = page
                this.last_page = this.data.last_page
                
                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })

            // master
            if(this.$route.params.rmNo){
                Gen.apiRest(
                    "/get/"+url+'?master=1', 
                    {
                    params: Object.assign({page: page}, paramsQuery, params.query||{})
                    }
                ).then(res=>{
                    console.log(res.data)
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            }
            
        },

        getPatientInfo() {
            this.$set(this.loading, 'patientInfo', true)
            Gen.apiRest(
            "/do/" + 'RanapPasienPerawatan', {
                data: {
                type: "get-data-pasien",
                id_registrasi: this.$route.query.regId
                }
            },
            "POST"
            ).then(resp => {
            this.$set(this, 'patientData', resp.data.data)

            this.$set(this.loading, 'patientInfo', false)
            })
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            data.aranrm_last_step = this.row.aranrm_last_step

            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:data}, 
                    "POST"
                )
            }
        },1000),

        // Assesmen Start
        
        removeICDForm(){
          
            this.$swal({
                icon: 'warning',
                title: 'Apakah Anda Yakin akan menghapus data ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
            }).then(result => {  
              this.loadingOverlay = true
              Gen.apiRest('/do/' + this.modulePage, {
                  data: {
                    type: 'remove-ic',
                    id: this.row.arankm_inform_consent_id
                  }
              }, 'POST').then(res => {
                  this.row.arankm_inform_consent_id = null
                  this.loadingOverlay = false
              })
            })
        },
        openInformConsentDetail(v){
            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'get-data-ic',
                    id: v.arankai9d_informed_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.mi9_name = v.mi9_name
                this.rowIC.mi9_code = v.mi9_code
                this.rowIC.id_icd = v.arankai9d_id
                this.openModalICDetail = true                   
            })
        },
        openInformConsent(v){
            if(!v.arankai9d_informed_consent){              
                this.rowIC.aranic_persetujuan_tindakan = null
                this.rowIC.aranic_is_pasien_setuju = null

                if(v.mi9_informed_consent){
                    Gen.apiRest('/do/' + this.modulePage, {
                        data: {
                            type: 'autofill-ic',
                            id: v.mi9_informed_consent,
                        }
                    }, 'POST').then(res => {
                        this.rowIC = res.data.row
                        this.rowIC.mi9_name = v.mi9_name
                        this.rowIC.mi9_code = v.mi9_code
                        this.rowIC.id_icd = v.arankai9d_id
                        this.rowIC.aranic_id = null
                    })
                }else{
                  this.rowIC.aranic_diagnosis = null
                  this.rowIC.aranic_id = null
                  this.rowIC.aranic_dasar_diagnosis = null
                  this.rowIC.aranic_tindakan_dokter = null
                  this.rowIC.aranic_indikasi_tindakan = null
                  this.rowIC.aranic_tata_cara = null
                  this.rowIC.aranic_tujuan = null
                  this.rowIC.aranic_risiko = null
                  this.rowIC.aranic_kompilasi = null
                  this.rowIC.aranic_prognosis = null
                  this.rowIC.aranic_alternatif = null
                  this.rowIC.aranic_lainnya = null
                }

            }else{  
                Gen.apiRest('/do/' + this.modulePage, {
                    data: {
                        type: 'get-data-ic',
                        id: v.arankai9d_informed_consent,
                    }
                }, 'POST').then(res => {
                    this.rowIC = res.data.row 
                    this.rowIC.mi9_name = v.mi9_name
                    this.rowIC.mi9_code = v.mi9_code
                    this.rowIC.id_icd = v.arankai9d_id                   
                })
                // console.log(edit)
            }

            this.rowIC.mi9_name = v.mi9_name
            this.rowIC.mi9_code = v.mi9_code
            this.rowIC.id_icd = v.arankai9d_id

            this.openModalIC = true
        },

        openInformConsentDetailForm(v){
            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'get-data-ic',
                    id: this.row.arankm_inform_consent_id,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.mi9_name = this.row.arankm_tindakan_text
                this.rowIC.mi9_code = ''
                this.rowIC.id_assesment = this.row.arankm_id
                this.openModalICDetail = true                   
            })
        },
        openInformConsentForm(){
          if(!this.row.arankm_inform_consent_id){
              this.rowIC.aranic_diagnosis = null
              this.rowIC.aranic_id = null
              this.rowIC.aranic_dasar_diagnosis = null
              this.rowIC.aranic_tindakan_dokter = null
              this.rowIC.aranic_indikasi_tindakan = null
              this.rowIC.aranic_tata_cara = null
              this.rowIC.aranic_tujuan = null
              this.rowIC.aranic_risiko = null
              this.rowIC.aranic_kompilasi = null
              this.rowIC.aranic_prognosis = null
              this.rowIC.aranic_alternatif = null
              this.rowIC.aranic_lainnya = null
              this.rowIC.mi9_name = this.row.arankm_tindakan_text
              this.rowIC.mi9_code = ''
              this.rowIC.id_assesment = this.row.arankm_id
          } else{
              Gen.apiRest('/do/' + this.modulePage, {
                  data: {
                      type: 'get-data-ic',
                      id: this.row.arankm_inform_consent_id,
                  }
              }, 'POST').then(res => {
                  this.rowIC = res.data.row 
                  this.rowIC.mi9_name = this.row.arankm_tindakan_text                
              })
          }

          this.openModalIC = true
        },

        submitInformConcent(){
            this.$refs['VFormInformconsent'].validate().then(success => {
                if(success){
                    this.openModalIC = false

                    let data = this.rowIC
                    data.type = 'submit-data-inform-consent'
                    data.aranic_aranr_id = this.rowReg.aranr_id
                    data.aranic_name = this.rowIC.mi9_name + (this.rowIC.mi9_code ? " ("+this.rowIC.mi9_code+")" : "")
                    
                    this.loadingOverlay = true
                    Gen.apiRest(
                    "/do/" + this.modulePage, {
                        data: data
                    },
                    "POST"
                    ).then(res => {
                        this.apiGet()  
                        
                        this.rowIC.aranic_id = null

                        return this.$swal({
                            title: "Data Informed Consent berhasil disimpan",
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                    })
                }
            })
        },
        ceilData(value) {
            return Math.ceil(value)
        },

        selectICD9(e) {
            let index = this.mICD9.findIndex(x => x.value == e)
            if (index !== -1) {
                this.inputICD9Name =  this.mICD9[index]['text']
                this.searchICD9Name = null
            }else{
                this.inputICD9Name =  null
            }
            this.addNewICD9()
        },

        selectICD10(e) {
            let index = this.mICD10.findIndex(x => x.value == e)
            if (index !== -1) {
                this.inputICD10Name =  this.mICD10[index]['text']
                this.searchICD10Name = null
            }else{
                this.inputICD10Name =  null
            }
            this.addNewICD10()
        },

        filterICD10: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.dataICD10 || []).length; i++) {
                if (this.dataICD10[i]['arankai10d_icd_10']) {
                    id.push(this.dataICD10[i]['arankai10d_icd_10'])
                }
            }
            
            this.searchICD10Name = e

            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'select-icd-10',
                    search: e,
                    id: id
                }
                }, 'POST').then(res => {
                this.mICD10 = res.data.data
            })
        }, 10),

        changePageICD10(page, k, act, col) {
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this[col][k]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                    page += 1
                    this[col][k]['currentPage'] += 1
                }
            }
            
            let data = {
                type: 'select-paging-icd-10',
                page: page,
                search: this.searchICD10Name
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                this.mICD10 = res.data.data
                this.getmICD10(this.dataICD10, k)
            })
        },

        filterICD9: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.dataICD9 || []).length; i++) {
                if (this.dataICD9[i]['arankai9d_icd_9']) {
                    id.push(this.dataICD9[i]['arankai9d_icd_9'])
                }
            }

            this.searchICD9Name = e

            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'select-icd-9',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.mICD9 = res.data.data
            })
        }, 10),

        changePageICD9(page, k, act, col) {
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this[col][k]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                    page += 1
                    this[col][k]['currentPage'] += 1
                }
            }
            let data = {
                type: 'select-paging-icd-9',
                page: page,
                search: this.searchICD9Name
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                this.mICD9 = res.data.data
                this.getmICD9(this.dataICD9, k)
            })
        },

        addNewICD9() {
            if(this.inputICD9){
            // if (!this.row.isEdit) {
            let data = {
                arankai9d_aranrm_id: this.row.aranrm_id,
                arankai9d_icd_9: this.inputICD9,
                type: 'add-icd-9'
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.dataICD9.push(resp.row)
                this.inputICD9 = null
                this.rowIC.aranic_id = null
            })
            // } else {
            //   let data = {
            //     arankai9d_aranrm_id: this.row.aranrm_id,
            //     arankai9d_id: null
            //   }
            //   this.dataICD9.push(data)
            // }
            }
        },
        removedataICD9(id, k) {
            // if (!this.row.isEdit) {
            this.$swal({
                icon: 'warning',
                title: 'Apakah Anda yakin ingin menghapus tindakan ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
            }).then(result => {
                if (result.value) {
                  let data = {
                      id: id,
                      type: 'remove-icd-9'
                  }
                  Gen.apiRest(
                  "/do/" + this.modulePage, {
                      data: data
                  },
                  "POST"
                  ).then(() => {
                      this.dataICD9.splice(k, 1)
                  })
                }
            })

           
            // } else {
            //   this.dataICD9.splice(k, 1)
            // }
        },

        addNewICD10() {
            if(this.inputICD10){
            // if (!this.row.isEdit) {
            let data = {
                arankai10d_aranrm_id: this.row.aranrm_id,
                arankai10d_icd_10: this.inputICD10,
                type: 'add-icd-10'
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.dataICD10.push(resp.row)
                this.inputICD10 = null
            })
            // } else {
            //   let data = {
            //     arankai10d_aranrm_id: this.row.aranrm_id,
            //     arankai10d_icd_10: this.inputICD10,
            //   }
            //   this.dataICD10.push(data)
            // }
            }
        },
        removedataICD10(id, k) {
            // if (!this.row.isEdit) {
            let data = {
            id: id,
            type: 'remove-icd-10'
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(() => {
            this.dataICD10.splice(k, 1)
            })
            // } else {
            //   this.dataICD10.splice(k, 1)
            // }
        },

        getmICD9(diagnosa, k) {
            const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
            return k2 != k
            }), v2 => {
            return v2.api9_icd_9
            })
            return _.filter(this.mICD9, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
            })
        },

        getmICD10(diagnosa, k) {
            const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
            return k2 != k
            }), v2 => {
            return v2.api10_icd_10
            })
            return _.filter(this.mICD10, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
            })
        },


        
        searchICD10Data: _.debounce(function () {
            let data = {
                search: this.searchICD10,
                aranr_ap_id: this.rowReg.aranr_ap_id,
                type: 'search-icd-10'
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.riwayatICD10 = resp.data
            })
        }, 500),

        searchICD9Data: _.debounce(function () {
            let data = {
                search: this.searchICD9,
                aranr_ap_id: this.rowReg.aranr_ap_id,
                type: 'search-icd-9'
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.riwayatICD9 = resp.data
            })
        }, 500),


        saveICD10() {
            if (this.selectedICD10) {
            let data = {
                id: this.selectedICD10,
                aranrm_id: this.row.aranrm_id,
                type: 'save-riwayat-icd-10'
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(() => {
                this.openriwayatICD10 = false
                this.resetData()
            })
            }
        },

        saveICD9() {
            if (this.selectedICD9) {
            let data = {
                id: this.selectedICD9,
                aranrm_id: this.row.aranrm_id,
                type: 'save-riwayat-icd-9'
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(() => {
                this.openriwayatICD9 = false
                this.resetData()
            })
            }
        },

        
        resetData() {
            this.searchICD10 = '',
            this.searchICD9 = '',
            this.apiGet()
        },

        // Assesmen Start

        doSubmit(){
            
            if(this.row.aranrm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                }).then(result => {
                    if (result.value) {
                        setTimeout(()=>{
                            let inv = []
                            let el = document.querySelectorAll('.label_error')
                            for(let i = 0; i < (el||[]).length; i++){
                                if(el[i].style.display !== 'none'){
                                    inv.push(el[i].id)
                                }
                            }
                            if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                        },500)
                    }
                })
            }   

            this.$refs['VFormDokter'].validate().then(success => {
                console.log(success)
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                console.log(el)
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                                            
                                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                })
                            }, 500)
                        }
                    })
                }
                if (success) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            data.dataICD9 = this.dataICD9
                            data.dataICD10 = this.dataICD10

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + this.modulePage, {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.$router.push({ name: 'RanapPasienPerawatanDPJP'}).catch(()=>{})   
                                })
                            })
                        }
                    })
                }
            })
        }
    },
    mounted() {
        this.apiGet() 

        this.sockets.subscribe('collaborate_kajian-awal', data => {
            if(data.regId != this.$route.query.regId){
                return
            }

            if(data.type == 'set-disable'){
                if(data.field == 'alergi-jenis'){
                    this.$set(this.disabled['alergi-jenis'], data.index, true)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.disabled['alergi-name'], data.index, true)
                }
                else{
                    this.$set(this.disabled, data.field, true)
                }
            }
            else if(data.type == 'set-enable'){
                if(data.field == 'alergi-jenis'){
                    this.$set(this.disabled['alergi-jenis'], data.index, false)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.disabled['alergi-name'], data.index, false)
                }
                else{
                    this.$set(this.disabled, data.field, false)
                }
            }
            else if(data.type == 'set-value'){
                if(data.field == 'keluhan-utama'){
                    this.$set(this.row, 'arankm_keluhan_utama', data.value)
                }
                else if(data.field == 'catatan-dokter'){
                    this.$set(this.row, 'arankm_riwayat_penyakit_sekarang', data.value)
                }
                else if(data.field == 'riwayat-penyakit-dahulu'){
                    this.$set(this.row, 'arankm_riwayat_penyakit', data.value)
                }
                else if(data.field == 'has-alergi'){
                    this.$set(this.row, 'arankm_has_alergi', data.value)
                }
                else if(data.field == 'alergi-jenis'){
                    this.$set(this.row.arankm_alergi[data.index], 'jenis', data.value)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.row.arankm_alergi[data.index], 'name', data.value)
                }
                else if(data.field == 'tekanan-darah-min'){
                    this.$set(this.row, 'arankm_ttv_tekanan_darah_min', data.value)
                }
                else if(data.field == 'tekanan-darah-max'){
                    this.$set(this.row, 'arankm_ttv_tekanan_darah_max', data.value)
                }
                else if(data.field == 'nadi'){
                    this.$set(this.row, 'arankm_ttv_nadi', data.value)
                }
                else if(data.field == 'nadi-label'){
                    this.$set(this.row, 'arankm_ttv_label', data.value)
                }
                else if(data.field == 'gula-darah'){
                    this.$set(this.row, 'arankm_ttv_gula_darah', data.value)
                }
                else if(data.field == 'pernafasan'){
                    this.$set(this.row, 'arankm_ttv_pernafasan', data.value)
                }
                else if(data.field == 'spo2'){
                    this.$set(this.row, 'arankm_ttv_spo2', data.value)
                }
                else if(data.field == 'suhu'){
                    this.$set(this.row, 'arankm_ttv_suhu', data.value)
                }
                else if(data.field == 'berat-badan'){
                    this.$set(this.row, 'arankm_ttv_weight', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'tinggi'){
                    this.$set(this.row, 'arankm_ttv_height', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'lingkar-kepala'){
                    this.$set(this.row, 'arankm_ttv_lingkar_kepala', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'permukaan-anak'){
                    this.$set(this.row, 'arankm_ttv_luas_permukaan_anak', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'response-mata'){
                    this.$set(this.row, 'arankm_response_mata', data.value)
                }
                else if(data.field == 'response-verbal'){
                    this.$set(this.row, 'arankm_response_verbal', data.value)
                }
                else if(data.field == 'response-motorik'){
                    this.$set(this.row, 'arankm_response_motorik', data.value)
                }
                else if(data.field == 'pupil'){
                    this.$set(this.row, 'arankm_ttv_pupil', data.value)
                }
            }
            else if(data.type == 'delete-alergi'){
                this.row.arankm_alergi.splice(data.index, 1)
            }
            else if(data.type == 'add-alergi'){
                this.row.arankm_alergi.push(data.value)
            }

            console.log(data)
        })
        
        this.getPatientInfo()
    },
    
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        tingkatKesadaranTotal(v){
            setTimeout(()=>{
                this.row.arankm_response_gcs = v
            },250)
        },
    },

    destroyed(){
        this.sockets.unsubscribe('collaborate_kajian-awal')
    }
}
</script>